import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Rule } from "../../Types";
import { formatAdornment } from "../../Utils";

interface IProps {
  rules: Rule[];
}
export const CollectiveAgreementRulesTable = ({ rules }: IProps) => {
  return (
    <>
      <div>
        <div className="adminTable">
          <Table className="table">
            <TableHead className="tableHead">
              <TableRow className="tableRow">
                <TableCell>Rule</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rules.map((rule, ruleIndex) => (
                <TableRow key={ruleIndex}>
                  <TableCell className="tableCell">{rule.title}</TableCell>
                  <TableCell className="tableCell">
                    {rule.value} {formatAdornment(rule.handler)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};
