// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ChangeEvent, Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Grid,
  CircularProgress,
  Hidden,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import "./PostingFull.scss";
import "./FreelancerFeedback.scss";
import { PostingModel } from "../../../../Models/PostingModel";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import {
  LanguageHelper,
  getLanguage,
  getDateFormatAccordingToLanguage,
} from "../../../../Helpers/LanguageHelper";
import { RatingStars } from "../../../Parts/General/RatingStars";
import { getDateObjectFromDateString } from "../../../../Helpers/StringExtractorHelper";
import { RequestHelper } from "../../../../Helpers/RequestHelper";
import { getCurrencyFormat } from "../../../../Helpers/LocalizationHelper";
import { CurrencyModel } from "../../../../Models/CurrencyModel";
import { getCurrentUser, setUserData } from "../../../../Helpers/SessionHelper";
import { LoginModel } from "../../../../Models/LoginModel";
import qs from "qs";
import { PostingHelper } from "../../../../Helpers/PostingHelper";
import { PageLoader } from "../../../Parts/General/PageLoader";
import { SkillModel } from "../../../../Models/SkillModel";
import { RequestModel, RequestStatuses } from "../../../../Models/RequestModel";
import { IsProfileIncomplete } from "./IsProfileIncomplete/IsProfileIncomplete";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";
import { FavoriteProfileModel } from "../../../../Models/FavoriteProfileModel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CMSHelper, CMSTypes } from "../../../../Helpers/CMSHelper";
import { resolvePostingSalaryPerHour } from "../../../../Modules/CollectiveAgreement/Utils";
import {
  getFreelancerProfileDetails,
  getSalaryPerSeries,
} from "../../../../Helpers/FreelancerProfileHelper";
import { Alert } from "@material-ui/lab";
import { getCurrentLanguage } from "../../../../Helpers/SessionHelper";
import { format, parseISO } from "date-fns";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RulesForm } from "../../../../Modules/CollectiveAgreement/Components/RulesForm";
import { RulesBySkillId } from "../../../../Modules/CollectiveAgreement/Types";
import { CollectiveAgreementRulesTable } from "../../../../Modules/CollectiveAgreement/Components/Table";

interface IProps extends RouteComponentProps {
  preview?: boolean;
  visiting?: boolean;
  posting: PostingModel;
  request?: RequestModel;
  viewState:
    | "company"
    | "freelancer-searching"
    | "freelancer-searched"
    | "visitor";
  isChabberFavourite?: boolean;
  favourites?: FavoriteProfileModel[];
  closePostingPreview?: () => void;
}

interface IState {
  months: string[];
  day: string;
  buttonsDisabled: boolean;
  showTranslation: boolean;
  user: LoginModel | null;
  isLoading: boolean;
  loadingForApplyButton: boolean;
  showCopyTooltip: boolean;
  contractContents: string;
  dialogueOpen: boolean;
  cancelationQuarantineRulesAcceptedAlready: boolean;
  cancelationQuarantineRulesAcceptedForm: boolean;
  shouldRenderJobConfirmationOrFinishedInfo: boolean;
  salaryV2Enabled?: boolean;
  perSeriesDataV2?: any;
  totalV2: number;
}

export class PostingFull extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  languageHelper = new LanguageHelper();
  requestHelper = new RequestHelper();
  postingHelper = new PostingHelper();
  cmsHelper = new CMSHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      months: [],
      day: "",
      buttonsDisabled: false,
      showTranslation: true,
      user: getCurrentUser(),
      isLoading: false,
      loadingForApplyButton: true,
      showCopyTooltip: false,
      contractContents: "",
      dialogueOpen: false,
      cancelationQuarantineRulesAcceptedAlready: false,
      cancelationQuarantineRulesAcceptedForm: false,
      shouldRenderJobConfirmationOrFinishedInfo: false,
      salaryV2Enabled: false,
    };

    this.applyButton = this.applyButton.bind(this);
    this.applyToJob = this.applyToJob.bind(this);
    this.paidTransport = this.paidTransport.bind(this);
    this.paidTransportAsPerCollectiveAgreement =
      this.paidTransportAsPerCollectiveAgreement.bind(this);
    this.getPayDay = this.getPayDay.bind(this);
    this.pushToLogin = this.pushToLogin.bind(this);
    this.titleAndAmountNeeded = this.titleAndAmountNeeded.bind(this);
    this.lockApplyButton = this.lockApplyButton.bind(this);
    this.maybeRenderJobConfirmationInfo =
      this.maybeRenderJobConfirmationInfo.bind(this);
    this.prepareText = this.prepareText.bind(this);
  }

  async componentDidMount() {
    await this.preparePage();
  }

  async preparePage() {
    this.setState({ isLoading: true });
    let user = getCurrentUser();

    if (!!user?.Freelancer) {
      const allRequests: RequestModel[] =
        await this.requestHelper.getAllRequestsForFreelancer();
      const request =
        allRequests.find(
          (request) => request.PostingId === this.props.posting.Id
        ) || null;

      this.setState({
        shouldRenderJobConfirmationOrFinishedInfo:
          request?.RequestStatus === RequestStatuses.Confirmed,
      });

      const urlParams = new URLSearchParams(window.location.search);
      const salaryV2Enabled = urlParams.get("salary_v2") || true; //disable before release
      if (salaryV2Enabled != null) {
        try {
          const response = await getSalaryPerSeries(this.props.posting.Id);
          this.setState({
            salaryV2Enabled: true,
            perSeriesDataV2: response.data.calcPerSeries,
            totalV2: response.data.total,
          });
        } catch (e) {
          console.log(e);
        }
      }

      try {
        const { data } = await getFreelancerProfileDetails(user?.Id);
        if (data) {
          this.setState((prev) => ({
            ...prev,
            cancelationQuarantineRulesAcceptedAlready: Boolean(
              data.CancelationQuarantineRulesAccepted
            ),
          }));
        }
      } catch (e) {
        this.setState((prev) => ({
          ...prev,
          cancelationQuarantineRulesAcceptedAlready: false,
        }));
      }
    }

    let posting = this.props.posting;

    if (!posting && this.props.location.state) {
      //@ts-ignore
      posting = this.props.location.state.activePosting;
    }

    if (!posting) {
      let id = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }).id;
      posting = await this.postingHelper.getPosting(id);
    }

    let localData = this.storageHelper.getFacadeLocalization();
    if (localData == null) return; // TODO: handle error

    let months = await this.languageHelper.getMonthsByLanguage(true);

    let days: string[] = [];
    //for-loop starts at 8 to match the language strings in the db.
    for (let i = 8; i <= 14; i++) {
      days.push(getLanguage(i));
    }

    let dateString = new Date(this.props.posting.StartAtLocal);
    //taking the 0 indexation into account
    let dayIndex = dateString.getDay() - 1;

    if (this.props.posting != null) {
      this.props.posting.StartAtLocalObject = await getDateObjectFromDateString(
        this.props.posting.StartAtLocal
      );
      this.props.posting.EndAtLocalObject = await getDateObjectFromDateString(
        this.props.posting.EndAtLocal
      );
    }

    await this.setState({
      months: months!,
      day: days[dayIndex],
      user,
      isLoading: false,
      // request
    });
  }

  showDateAndSalary() {
    let salaryPerHour = resolvePostingSalaryPerHour(this.props.posting) / 100;
    const hours = Math.floor(this.props.posting.JobLengthInMinutes / 60);
    let fullSalary = (salaryPerHour * hours).toFixed(2);

    let currency: CurrencyModel = new CurrencyModel({});
    if (this.props.posting && this.props.posting.Currency) {
      currency = this.props.posting.Currency;
    } else if (this.props.request && this.props.request.Currency) {
      currency = this.props.request.Currency;
    }

    let salaryDiv;
    if (this.state.user) {
      let salaryString = getCurrencyFormat(currency, true, salaryPerHour);
      let fullSalaryString = getCurrencyFormat(currency, false, fullSalary);
      salaryDiv = (
        <div>
          <i className="fas fa-coins fa-fw" /> {salaryString} (
          {getLanguage(744, "{{amount}} total").replace(
            "{{amount}}",
            fullSalaryString
          )}
          )
        </div>
      );
    }

    if (
      this.props.posting &&
      this.props.posting.StartAtLocalObject &&
      this.props.posting.EndAtLocalObject
    ) {
      return (
        <div>
          <div>
            <i className="far fa-calendar fa-fw" />{" "}
            {this.props.posting && this.props.posting.StartAtLocal
              ? getDateFormatAccordingToLanguage(
                  this.props.posting.StartAtLocal,
                  true,
                  true
                )
              : ""}
          </div>
          <br />
          {!this.state.salaryV2Enabled && (
            <>
              <div>
                <i className="far fa-clock fa-fw" />{" "}
                {this.props.posting.StartAtLocalObject.time} -{" "}
                {this.props.posting.EndAtLocalObject.time}
              </div>
              <br />
              {salaryDiv}{" "}
            </>
          )}

          {this.state.salaryV2Enabled && (
            <>
              <div>
                {this.state.perSeriesDataV2.map((series) => (
                  <div>
                    <i className="far fa-clock fa-fw" />{" "}
                    {format(parseISO(series.start), "HH:mm")} -{" "}
                    {format(parseISO(series.end), "HH:mm")}:{" "}
                    {(series.salary / 100 / (series.groupLength / 2)).toFixed(
                      2
                    )}{" "}
                    kr./h
                  </div>
                ))}
                <br />
                <i className="far fa-dollar-sign fa-fw" /> Total:{" "}
                {(this.state.totalV2 / 100).toFixed(2)} kr.
              </div>
            </>
          )}
        </div>
      );
    }
    return;
  }
  jobAddress() {
    if (this.props.posting && this.props.posting.StartAtLocalObject) {
      let jobDistance: string = getLanguage(437, "({{distance}} km)");

      if (
        this.props?.posting?.DistanceFromUserInKm &&
        this.props?.posting?.DistanceFromUserInKm >= 0
      ) {
        jobDistance = jobDistance.replace(
          "{{distance}}",
          this.props?.posting?.DistanceFromUserInKm?.toString()
        );
      } else {
        jobDistance = "";
      }

      return (
        <div>
          <p
            className="cursorPointer"
            onClick={() =>
              window.open(
                `https://maps.google.com/?q=${this.props.posting.AddressLine1}, ${this.props.posting.ZipCode} ${this.props.posting.City}`,
                "_blank"
              )
            }
          >
            <i className="fas fa-map-marker-alt fa-fw" />{" "}
            {this.props.posting.AddressLine1}, {this.props.posting.ZipCode}{" "}
            {this.props.posting.City}
            <span>{jobDistance}</span>{" "}
            <span>
              {" "}
              <i className="fas fa-external-link-alt fa-fw fa-xs"></i>
            </span>
          </p>
        </div>
      );
    }
    return;
  }

  applyButton() {
    if (this.props.request != null) {
      if (
        this.props.posting.ReopenedDate &&
        this.props.request.RequestStatus === 1
      ) {
        // If lastaction happened at or after the reopen, user should not be able to apply again
        if (this.props.request.LastAction >= this.props.posting.ReopenedDate) {
          return;
        }
      } else return;
    }

    if (this.props.visiting) {
      return (
        <div className="text-center margin-v-32">
          <Button
            className="button-gradient"
            onClick={this.pushToLogin}
            disabled={this.state.buttonsDisabled}
          >
            {getLanguage(264, "Please log in to apply")}
            {this.state.buttonsDisabled && (
              <CircularProgress size={20} className="buttonLoader" />
            )}
          </Button>
        </div>
      );
    } else {
      if (this.props.viewState === "freelancer-searching") {
        let title = getLanguage(265, "Apply");
        if (this.props.isChabberFavourite === true) {
          title = getLanguage(266, "Take job");
        }

        let distanceWarning = "";
        if (
          this.props.posting.DistanceFromUserInKm &&
          this.props.posting.DistanceFromUserInKm > 30
        ) {
          distanceWarning = getLanguage(
            767,
            "Please take note of the distance from your home to the job"
          );
        }
        return (
          <div className="text-center margin-v-32">
            <Button
              className="button-gradient"
              onClick={this.applyToJob}
              disabled={
                this.state.buttonsDisabled ||
                !(
                  this.state.cancelationQuarantineRulesAcceptedForm ||
                  this.state.cancelationQuarantineRulesAcceptedAlready
                )
              }
            >
              {title}
              {this.state.buttonsDisabled &&
              this.state.loadingForApplyButton ? (
                <CircularProgress size={20} className="buttonLoader" />
              ) : null}
            </Button>
            <p className="placeholder"> {distanceWarning}</p>
          </div>
        );
      } else return <></>;
    }
  }

  pushToLogin() {
    this.props.history.push({
      pathname: "/login",
      search: "redirect=/searchpostings" + this.props.history.location.search,
    });
  }

  lockApplyButton() {
    this.setState({ buttonsDisabled: true, loadingForApplyButton: false });
  }

  async applyToJob() {
    await this.setState({ buttonsDisabled: true });

    let updatedUser = this.state.user;
    if (updatedUser && updatedUser.Freelancer) {
      updatedUser.Freelancer.AcceptedContract = true;
      await setUserData(updatedUser);
    }

    if (this.props.posting) {
      let response = await this.requestHelper.applyForPosting(
        this.props.posting.Id
      );
      this.storageHelper.sendEvent("trackEvent", {
        event: "ApplyForJob",
        data: "Freelance",
      });

      if (response) {
        if (!this.props.preview) {
          await this.props.history.push("/freelancer/shifts");
        } else if (this.props.closePostingPreview) {
          await this.props.closePostingPreview();
        }
      } else {
        SnackbarManager.Instance.addWarning(
          getLanguage(267, "Application could not be sent.")
        );
        await this.setState({ buttonsDisabled: false });
      }
    } else return;
  }

  paidTransportAsPerCollectiveAgreement() {
    if (this.props.posting && this.props.posting.CoveredByCollectiveAgreement) {
      return (
        <div>
          <p>
            <i className="fas fa-bus-alt fa-fw" />{" "}
            {getLanguage(
              956,
              "paid transportation expenses according to collective agreement"
            )}
          </p>
        </div>
      );
    }
  }

  paidTransport() {
    if (this.props.posting && this.props.posting.PaysTransport > 0) {
      return (
        <div>
          <p>
            <i className="fas fa-bus-alt fa-fw" />{" "}
            {this.props.posting.PaysTransport / 60}{" "}
            {getLanguage(269, "hour salary for transportation")}
          </p>
        </div>
      );
    }
    return;
  }

  requiresExperience() {
    if (this.props.posting && this.props.posting.RequiresRelevantExperience) {
      return (
        <div>
          <p>
            <i className="fas fa-check fa-fw" />{" "}
            {getLanguage(429, "Relevant experience required")}
          </p>
        </div>
      );
    }
    return;
  }

  selfPaidBreak() {
    if (this.props.posting?.SelfPaidBreak === true) {
      return (
        <div>
          <p>
            <i className="fas fa-utensils fa-fw" />{" "}
            {getLanguage(903, "Self-paid break")}: {getLanguage(810, "Yes")}
          </p>
        </div>
      );
    } else if (this.props.posting?.SelfPaidBreak === false) {
      return (
        <div>
          <p>
            <i className="fas fa-utensils fa-fw" />{" "}
            {getLanguage(903, "Self-paid break")}: {getLanguage(281, "No")}
          </p>
        </div>
      );
    }
    return;
  }

  mealIncluded() {
    if (this.props.posting?.MealIncluded === true) {
      return (
        <div>
          <p>
            <i className="fas fa-utensils fa-fw" />{" "}
            {getLanguage(811, "Meal included")}: {getLanguage(810, "Yes")}
          </p>
        </div>
      );
    } else if (this.props.posting?.MealIncluded === false) {
      return (
        <div>
          <p>
            <i className="fas fa-utensils fa-fw" />{" "}
            {getLanguage(811, "Meal included")}: {getLanguage(281, "No")}
          </p>
        </div>
      );
    }
    return;
  }

  getPayDay() {
    let payDate;
    if (this.props.request) {
      payDate = this.props.request.DateForSalaryPayout;
    } else {
      payDate = this.props.posting.DateForSalaryPayout;
    }

    if (payDate && payDate !== "") {
      let payDateObject = getDateObjectFromDateString(payDate);

      return (
        <div>
          <p>
            <i className="fas fa-coins fa-fw" /> {getLanguage(270, "Pay-day:")}{" "}
            {payDateObject.day}/{payDateObject.month}/{payDateObject.year}
          </p>
        </div>
      );
    } else return;
  }

  titleAndAmountNeeded() {
    if (this.props.posting) {
      if (this.props.posting.AmountNeeded && this.props.posting.Skill) {
        let skill = this.storageHelper
          .getSkillsFromLocal()!
          .find((x) => x.Id === this.props.posting.Skill!.Id) as SkillModel;
        let titleCode = skill.getLanguageCode(
          this.props.posting.AmountNeeded,
          true
        );
        let title = getLanguage(titleCode, "jobtitle");
        return (
          <h2 className="margin-0 brand">
            {this.props.posting.AmountNeeded} {title}
          </h2>
        );
      }
    }
    return <div></div>;
  }

  favouriteBadge() {
    if (this.props.favourites == null || this.props.favourites.length < 1)
      return;

    let fave = this.props.favourites.find(
      (x) => x.CompanyProfileId === this.props.posting.CompanyId
    );
    if (fave?.ApprovalStatus === 1)
      return (
        <div className="favouriteIndicator inputGroup_item">
          <i className="fas fa-heart fa-lg" />
        </div>
      );
    return null;
  }
  renderCopyButton() {
    if (getCurrentUser()?.Company) return;

    let copyString = window.location.hostname;
    if (copyString === "localhost") {
      copyString += ":" + window.location.port;
    }
    copyString += "/searchpostings" + window.location.search;

    return (
      <div className="inline-block inputGroup_item">
        <Hidden only="xs">
          <Tooltip
            open={this.state.showCopyTooltip}
            onClose={() => this.setState({ showCopyTooltip: false })}
            title={getLanguage(839, "Copied to clipboard!")}
            leaveDelay={200}
            placement={"top"}
          >
            <CopyToClipboard text={copyString}>
              <Button
                className=""
                variant="text"
                size="medium"
                onClick={() => this.setState({ showCopyTooltip: true })}
              >
                <span className="btn-icon">
                  <i className="fas fa-share-square" />
                </span>
                {getLanguage(837, "Share")}
              </Button>
            </CopyToClipboard>
          </Tooltip>
        </Hidden>
        <Hidden smUp>
          <Tooltip
            open={this.state.showCopyTooltip}
            onClose={() => this.setState({ showCopyTooltip: false })}
            title={getLanguage(839, "Copied to clipboard!")}
            leaveDelay={200}
            placement={"top"}
          >
            <CopyToClipboard text={window.location.href}>
              <Button
                className="icon"
                variant="text"
                size="medium"
                onClick={() => this.setState({ showCopyTooltip: true })}
              >
                <span>
                  <i className="fas fa-share-square"> </i>
                </span>
              </Button>
            </CopyToClipboard>
          </Tooltip>
        </Hidden>
      </div>
    );
  }

  prepareText = async (contentType: CMSTypes) => {
    let address = "";
    if (this.state.user.Freelancer && this.state.user.Freelancer.AddressLine1) {
      address = this.state.user.Freelancer.AddressLine1;
      if (this.state.user.Freelancer.AddressLine2) {
        address += " " + this.state.user.Freelancer.AddressLine2;
      }
    }

    let country = this.storageHelper.getFacadeCountry();
    if (country == null) {
      SnackbarManager.Instance.addError(getLanguage(469, "Unknown error"));
      return;
    }

    const primaryLanguage = getCurrentLanguage();

    let content = await this.cmsHelper.getPrimaryDraftByCountry(
      country.Id,
      contentType,
      primaryLanguage
    );

    if (content == null) {
      SnackbarManager.Instance.addError(getLanguage(469, "Unknown error"));
      return;
    }

    if (contentType === CMSTypes.FreelancerContract) {
      const dateToBePlacedOnContract = getDateFormatAccordingToLanguage(
        new Date().toString(),
        true,
        false
      );

      content = this.cmsHelper.transformText(content, [
        {
          from: "%aktueldato%",
          to: dateToBePlacedOnContract,
        },
        {
          from: "%navn%",
          to: this.state.user.Firstnames + " " + this.state.user.Lastname,
        },
        {
          from: "%adresse%",
          to: address,
        },
        {
          from: "%postnr%",
          to: this.state.user.Freelancer.ZipCode,
        },
        {
          from: "%city%",
          to: this.state.user.Freelancer.City,
        },
      ]);
    }

    this.setState({ contractContents: content.Text, dialogueOpen: true });
  };

  maybeRenderJobConfirmationInfo() {
    if (!this.state.shouldRenderJobConfirmationOrFinishedInfo) return null;

    return (
      <div className="feedbackComponent">
        <div className="paper radius-12 padding-24 margin-bottom-16">
          <Grid container justify="center" alignItems="left">
            <Grid item container spacing={3} alignItems="center">
              <Grid item>
                <i className="fas fa-info-circle fa-lg custom-div" />
              </Grid>
              <Grid sm={"auto"}>
                <div className="info-text">
                  <div>
                    <div>
                      <strong>
                        {getLanguage(
                          907,
                          "Job confirmation between Chabber Danmark ApS &"
                        )}{" "}
                        {this.state.user?.Firstnames +
                          " " +
                          this.state.user?.Lastname}
                      </strong>
                      <div>
                        {getLanguage(
                          908,
                          "On behalf of Chabber Danmark Aps, you are sent to work for"
                        )}{" "}
                        {this.props.posting.Company.CompanyName}
                      </div>
                      {getLanguage(
                        909,
                        "For info regarding holiday, notice of termination and the like we refer you to your"
                      )}
                      <strong
                        className="cursorPointer"
                        onClick={() =>
                          this.prepareText(CMSTypes.FreelancerContract)
                        }
                      >
                        {" "}
                        {getLanguage(910, "Affiliation Agreement")}
                      </strong>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  render() {
    let mustBring: string | JSX.Element = "";
    if (this.props.posting.MustBring !== "") {
      if (this.props.posting.MustBringNonLocalised) {
        if (this.state.showTranslation === true) {
          mustBring = (
            <span>
              <p> {this.props.posting.MustBring}</p>
              <div>
                <Button
                  size="small"
                  onClick={() => this.setState({ showTranslation: false })}
                >
                  {getLanguage(271, "translated")} -{" "}
                  {getLanguage(272, "Show original")}
                </Button>
              </div>
            </span>
          );
        } else {
          mustBring = (
            <span>
              <p> {this.props.posting.MustBringNonLocalised}</p>
              <div>
                <Button
                  size="small"
                  onClick={() => this.setState({ showTranslation: true })}
                >
                  {getLanguage(273, "Translate")}
                </Button>
              </div>
            </span>
          );
        }
      } else {
        mustBring = this.props.posting.MustBring;
      }
    } else {
      mustBring = getLanguage(274, "No item listed");
    }

    let description: string | JSX.Element = "";
    if (this.props.posting.Description !== "") {
      if (this.props.posting.DescriptionNonLocalised) {
        if (this.state.showTranslation === true) {
          description = (
            <span>
              {" "}
              {this.props.posting.Description}
              <div>
                <Button
                  size="small"
                  onClick={() => this.setState({ showTranslation: false })}
                >
                  {getLanguage(271, "translated")} -{" "}
                  {getLanguage(272, "Show original")}
                </Button>
              </div>
            </span>
          );
        } else {
          description = (
            <span>
              {" "}
              {this.props.posting.DescriptionNonLocalised}
              <div>
                <Button
                  size="small"
                  onClick={() => this.setState({ showTranslation: true })}
                >
                  {getLanguage(273, "Translate")}
                </Button>
              </div>
            </span>
          );
        }
      } else {
        description = this.props.posting.Description;
      }
    } else {
      description = getLanguage(275, "No description added");
    }

    let style = {
      backgroundImage: "",
    };
    if (
      this.props.posting.Company != null &&
      this.props.posting.Company.CoverImagePath != null
    ) {
      style.backgroundImage =
        "url(" +
        this.props.posting.Company.CoverImagePath.replace(
          "Assets",
          "img"
        ).replace("assets", "img") +
        ")";
    }

    let pageloader = this.state.isLoading ? <PageLoader /> : null;

    return (
      <div className="postingFull">
        {pageloader}
        <Dialog
          open={this.state.dialogueOpen}
          onClose={(_) => this.setState({ dialogueOpen: false })}
        >
          <DialogContent>
            <DialogContentText
              dangerouslySetInnerHTML={{
                __html: this.state.contractContents,
              }}
            />
          </DialogContent>
        </Dialog>

        <div className="postingFull_cover" style={style} />
        <div className="postingFull_top">
          <Grid container justify="flex-start">
            <Grid item>
              <ProfilePictureViewer
                login={this.props.posting.Company!.Login}
                company={this.props.posting.Company}
                freelancer={null}
                customClass="postingFull_image"
                altText="profile"
              ></ProfilePictureViewer>
            </Grid>
            <Grid item sm xs={12}>
              <Grid container justify="space-between">
                <Grid item>
                  <h1 className="postingFull_name">
                    {this.props.posting && this.props.posting.Company
                      ? this.props.posting.Company.CompanyName
                      : ""}
                  </h1>
                  {this.titleAndAmountNeeded()}
                  <div className="postingFull_rating">
                    <RatingStars
                      company={
                        this.props.posting && this.props.posting.Company
                          ? this.props.posting.Company
                          : null
                      }
                      freelancer={null}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className="inputGroup">
                    {this.renderCopyButton()}
                    {this.favouriteBadge()}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="postingFull_content">
          {this.maybeRenderJobConfirmationInfo()}
          <div className="postingFull_Section">
            <h2 className="postingFull_SectionTitle">
              {getLanguage(276, "Time and salary")}
            </h2>
            <div className="postingFull_SectionContent">
              <div>{this.showDateAndSalary()}</div>
            </div>
          </div>
          <div className="postingFull_Section">
            <h2 className="postingFull_SectionTitle">
              Collective agreement rules
            </h2>
            <div className="postingFull_SectionContent">
              <div>
                {" "}
                <CollectiveAgreementRulesTable
                  rules={(
                    JSON.parse(
                      this.props.posting.Company?.CollectiveAgreementRules
                    ) as RulesBySkillId[]
                  )
                    .find((x) => x.skillId === this.props.posting.SkillId)
                    ?.rules.map((x) => ({
                      ...x,
                      value: (Number.parseInt(x.value) / 100).toFixed(2),
                    }))}
                />
              </div>
            </div>
          </div>
          <div className="postingFull_Section">
            <h2 className="postingFull_SectionTitle">
              {getLanguage(277, "Must bring")}
            </h2>
            <div className="postingFull_SectionContent">
              <i className="fas fa-tshirt fa-fw" />
              {mustBring}
            </div>
          </div>
          <div className="postingFull_Section">
            <h2 className="postingFull_SectionTitle">
              {getLanguage(278, "Job description")}
            </h2>
            <div className="postingFull_SectionContent">
              <div className="row">
                <i className="fas fa-info-circle fa-fw job-description-icon" />
                <div className="postingFull_JobDescription">{description}</div>
              </div>
            </div>
          </div>
          {this.state.shouldRenderJobConfirmationOrFinishedInfo &&
            !!this.props.posting?.Contact?.length && (
              <div className="postingFull_Section">
                <h2 className="postingFull_SectionTitle">
                  {getLanguage(
                    955,
                    "Contact information on person in charge of job"
                  )}
                </h2>
                <div className="postingFull_SectionContent">
                  <div className="row">
                    <i className="fas fa-phone fa-fw job-description-icon" />
                    <div className="postingFull_JobDescription">
                      {this.props.posting.Contact}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="postingFull_Section">
            <h2 className="postingFull_SectionTitle">
              {getLanguage(279, "Other details")}
            </h2>
            <div className="postingFull_SectionContent">
              <div>
                {this.state.user &&
                this.state.user.LoginType &&
                this.state.user.LoginType === 1
                  ? this.getPayDay()
                  : ""}
              </div>
              {this.requiresExperience()}
              {this.paidTransport()}
              {this.paidTransportAsPerCollectiveAgreement()}
              {this.selfPaidBreak()}
              {this.mealIncluded()}
              <p>
                <i className="fas fa-clock fa-fw" />{" "}
                {getLanguage(280, "Posible overtime")}
                {this.props.posting &&
                this.props.posting.LikelihoodOfOvertime !== 0
                  ? ": (" +
                    this.props.posting.LikelihoodOfOvertime / 60 +
                    " hours)"
                  : getLanguage(281, "No")}
              </p>
              <div>{this.jobAddress()}</div>
            </div>
          </div>
          <div className="postingFull_bottom">
            {this.state.cancelationQuarantineRulesAcceptedAlready &&
              this.state.user?.LoginType === 1 && (
                <Alert severity="warning">
                  {getLanguage(
                    904,
                    "I understand that cancelling a confirmed shift less than 4 hours prior to it's start will result in my profile being quarantined, and that cancelling a confirmed shift will show on my profile no matter when I cancel."
                  )}
                </Alert>
              )}
            {!this.state.cancelationQuarantineRulesAcceptedAlready &&
              !this.props.request &&
              this.state.user?.LoginType === 1 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        this.state.cancelationQuarantineRulesAcceptedForm
                      }
                      onChange={() => {
                        this.setState((prev) => ({
                          ...prev,
                          cancelationQuarantineRulesAcceptedForm:
                            !this.state.cancelationQuarantineRulesAcceptedForm,
                        }));
                      }}
                    />
                  }
                  label={getLanguage(
                    904,
                    "I understand that cancelling a confirmed shift less than 4 hours prior to it's start will result in my profile being quarantined, and that cancelling a confirmed shift will show on my profile no matter when I cancel."
                  )}
                />
              )}
            {!this.state.user || this.state.user?.LoginType === 1
              ? this.applyButton()
              : ""}
          </div>
        </div>
        <IsProfileIncomplete
          history={this.props.history}
          match={this.props.match}
          location={this.props.location}
          lockButtons={this.lockApplyButton}
          checkForExpiration={this.props.posting.StartAtLocal}
        />
      </div>
    );
  }
}
